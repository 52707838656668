/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'theme/variables';

html {
  font-size: 10px;
  line-height: 1.5;
}

a {
  cursor: pointer;
}

.phone {
  position: absolute;
  top: 49.4%;
  left: 49.95%;
  transform: translateX(-50%) translateY(-50%);
  width: auto;
  height: 950px;
  max-height: 95%;
  pointer-events: none;
  z-index: -1;
}

.splash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

//ion-scroll {
//    .scroll-content {
//        margin-top: 0 !important;
//    }
//}

//.title-md {
//    color: #424242;
//}

.page-header {
  font-size: 1.8rem;
  font-weight: 500;
  margin: 1.8rem;
}

// HACK: Ionic can sometimes eliminate top margin on scroll/fixed content, so force it here.
//.content-ios {
//    .scroll-content {
//        margin-top: 5.4rem;
//    }
//
//    .fixed-content {
//        margin-top: 5.4rem;
//    }
//}
//
//.content-md {
//    .scroll-content {
//        margin-top: 5.6rem;
//        padding-bottom: 0 !important;
//    }
//
//    .fixed-content {
//        margin-top: 5.6rem;
//    }
//}

.label-text-wrapper {
  color: $color-primary;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

ion-select {
  white-space: normal;
}

.ios {
  ion-icon[name="settings"] {
    transform: translateY(-.2rem);
  }
}

ion-title {
  img {
    height: 4rem;
    transform: translateY(1px);
  }

  &.md {
    img {
      transform: translateY(3px);
    }
  }
}

ion-list {
  margin-bottom: 3.2rem;

  &.md {
    padding: 0;
    margin-bottom: 3.2rem;
  }
}

//ion-list {
//  ion-item,
//  ion-item-sliding {
//    &.item-disabled {
//      opacity: 1;
//      color: rgba(0, 0, 0, .3);
//    }
//
//    position: relative;
//  }
//
//  &.ios,
//  &.md {
//    > ion-item:first-child:before,
//    > ion-item:after,
//    > ion-item-sliding:first-child:before,
//    > ion-item-sliding:after {
//      content: '';
//      display: block;
//      width: 100%;
//      height: .1rem;
//      background: $color-input-border;
//      position: absolute;
//      left: 0;
//      z-index: 3;
//    }
//
//    > ion-item:first-child:before,
//    > ion-item-sliding:first-child:before {
//      top: 0;
//    }
//
//    > ion-item:after,
//    > ion-item-sliding:after {
//      bottom: 0;
//    }
//
//    > ion-item:not(:last-child):after,
//    > ion-item-sliding:not(:last-child):after {
//      margin-left: 1.6rem;
//    }
//  }
//
//  &.md {
//    > ion-item,
//    > ion-item-sliding {
//      &.item-has-focus,
//      &.ion-invalid.ion-touched {
//        &:after {
//          display: none;
//        }
//      }
//    }
//  }
//}

//ion-item-options .button {
//  padding: 0 1em !important;
//}

button[disabled] {
  opacity: .4 !important;
}

ion-fab {
  margin: 1.4rem;
}

//.searchbar-input.sc-ion-searchbar-md {
//  background: white;
//}

.grid,
.col,
.row {
  padding: 0;
}

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 2.4rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

*:focus {
  outline: none;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-bold {
  font-weight: 600;
}

.text-semibold {
  font-weight: 500;
}

.text-italic {
  font-style: italic;
}

.text-line-through {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.table-header {
  font-weight: bold;
}

.table-row {
  height: 2rem;
  border-top: thin;
  border-color: rgba(0, 0, 0, .5);
}

.full-size {
  width: 100%;
  height: 100%;
}

.position-relative {
  position: relative;
}

.no-pl {
  padding-left: 0;
}

.no-pr {
  padding-right: 0;
}

.no-pt {
  padding-top: 0;
}

.no-pb {
  padding-bottom: 0;
}

.no-ml {
  margin-left: 0;
}

.no-mr {
  margin-right: 0;
}

.no-mt {
  margin-top: 0;
}

.no-mb {
  margin-bottom: 0;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.no-radius {
  border-radius: 0;
}

.display-inline {
  display: inline;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

// Padding helpers
// First 20, excluding multiples of 5 and 8.
@for $i from 1 through 20 {
  @if ($i % 5 != 0 and $i % 8 != 0) {
    .p-#{$i} {
      padding: #{($i * .1) + 'rem'} !important;
    }

    .pt-#{$i} {
      padding-top: #{($i * .1) + 'rem'} !important;
    }

    .pr-#{$i} {
      padding-right: #{($i * .1) + 'rem'} !important;
    }

    .pb-#{$i} {
      padding-bottom: #{($i * .1) + 'rem'} !important;
    }

    .pl-#{$i} {
      padding-left: #{($i * .1) + 'rem'} !important;
    }

    .ph-#{$i} {
      padding-left: #{($i * .1) + 'rem'} !important;
      padding-right: #{($i * .1) + 'rem'} !important;
    }

    .pv-#{$i} {
      padding-top: #{($i * .1) + 'rem'} !important;
      padding-bottom: #{($i * .1) + 'rem'} !important;
    }
  }
}

// Multiples of 5, max:100
@for $i from 0 through 20 {
  .p-#{$i * 5} {
    padding: #{($i * .5) + 'rem'} !important;
  }

  .pt-#{$i * 5} {
    padding-top: #{($i * .5) + 'rem'} !important;
  }

  .pr-#{$i * 5} {
    padding-right: #{($i * .5) + 'rem'} !important;
  }

  .pb-#{$i * 5} {
    padding-bottom: #{($i * .5) + 'rem'} !important;
  }

  .pl-#{$i * 5} {
    padding-left: #{($i * .5) + 'rem'} !important;
  }

  .ph-#{$i * 5} {
    padding-left: #{($i * .5) + 'rem'} !important;
    padding-right: #{($i * .5) + 'rem'} !important;
  }

  .pv-#{$i * 5} {
    padding-top: #{($i * .5) + 'rem'} !important;
    padding-bottom: #{($i * .5) + 'rem'} !important;
  }
}

// Multiples of 8, Max: 96, Excluding 0 and multiples of 5
@for $i from 1 through 12 {
  @if (($i * 8) % 5 != 0) {
    .p-#{$i * 8} {
      padding: #{($i * .8) + 'rem'} !important;
    }

    .pt-#{$i * 8} {
      padding-top: #{($i * .8) + 'rem'} !important;
    }

    .pr-#{$i * 8} {
      padding-right: #{($i * .8) + 'rem'} !important;
    }

    .pb-#{$i * 8} {
      padding-bottom: #{($i * .8) + 'rem'} !important;
    }

    .pl-#{$i * 8} {
      padding-left: #{($i * .8) + 'rem'} !important;
    }

    .ph-#{$i * 8} {
      padding-left: #{($i * .8) + 'rem'} !important;
      padding-right: #{($i * .8) + 'rem'} !important;
    }

    .pv-#{$i * 8} {
      padding-top: #{($i * .8) + 'rem'} !important;
      padding-bottom: #{($i * .8) + 'rem'} !important;
    }
  }
}

// Margin helpers
// First 20, excluding multiples of 5 and 8.
@for $i from 1 through 20 {
  @if ($i % 5 != 0 and $i % 8 != 0) {
    .m-#{$i} {
      margin: #{($i * .1) + 'rem'} !important;
    }

    .mt-#{$i} {
      margin-top: #{($i * .1) + 'rem'} !important;
    }

    .mr-#{$i} {
      margin-right: #{($i * .1) + 'rem'} !important;
    }

    .mb-#{$i} {
      margin-bottom: #{($i * .1) + 'rem'} !important;
    }

    .ml-#{$i} {
      margin-left: #{($i * .1) + 'rem'} !important;
    }

    .mh-#{$i} {
      margin-left: #{($i * .1) + 'rem'} !important;
      margin-right: #{($i * .1) + 'rem'} !important;
    }

    .mv-#{$i} {
      margin-top: #{($i * .1) + 'rem'} !important;
      margin-bottom: #{($i * .1) + 'rem'} !important;
    }
  }
}

// Multiples of 5, max:100
@for $i from 0 through 20 {
  .m-#{$i * 5} {
    margin: #{($i * .5) + 'rem'} !important;
  }

  .mt-#{$i * 5} {
    margin-top: #{($i * .5) + 'rem'} !important;
  }

  .mr-#{$i * 5} {
    margin-right: #{($i * .5) + 'rem'} !important;
  }

  .mb-#{$i * 5} {
    margin-bottom: #{($i * .5) + 'rem'} !important;
  }

  .ml-#{$i * 5} {
    margin-left: #{($i * .5) + 'rem'} !important;
  }

  .mh-#{$i * 5} {
    margin-left: #{($i * .5) + 'rem'} !important;
    margin-right: #{($i * .5) + 'rem'} !important;
  }

  .mv-#{$i * 5} {
    margin-top: #{($i * .5) + 'rem'} !important;
    margin-bottom: #{($i * .5) + 'rem'} !important;
  }
}

// Multiples of 8, Max: 96, Excluding 0 and multiples of 5
@for $i from 1 through 12 {
  @if (($i * 8) % 5 != 0) {
    .m-#{$i * 8} {
      margin: #{($i * .8) + 'rem'} !important;
    }

    .mt-#{$i * 8} {
      margin-top: #{($i * .8) + 'rem'} !important;
    }

    .mr-#{$i * 8} {
      margin-right: #{($i * .8) + 'rem'} !important;
    }

    .mb-#{$i * 8} {
      margin-bottom: #{($i * .8) + 'rem'} !important;
    }

    .ml-#{$i * 8} {
      margin-left: #{($i * .8) + 'rem'} !important;
    }

    .mh-#{$i * 8} {
      margin-left: #{($i * .8) + 'rem'} !important;
      margin-right: #{($i * .8) + 'rem'} !important;
    }

    .mv-#{$i * 8} {
      margin-top: #{($i * .8) + 'rem'} !important;
      margin-bottom: #{($i * .8) + 'rem'} !important;
    }
  }
}

.font-weight-light {
  font-weight: $font-weight-light !important;
}

.font-weight-normal {
  font-weight: $font-weight-normal !important;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold !important;
}

.font-weight-bold {
  font-weight: $font-weight-bold !important;
}

.ng-hide.ng-hide-animate {
  display: none !important;
}

.input-container {
  max-width: 40rem;
}

*[flex] {
  flex: 1;
}

*[flex-none] {
  flex: 0 0 auto;
}

*[layout="row"] {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;

  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  @for $i from 1 through 20 {
    > *[flex-#{$i * 5}] {
      min-width: #{($i * 5) + '%'};
      max-height: 100%;
    }
  }
}

*[layout="column"] {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;

  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  @for $i from 1 through 20 {
    > *[flex-#{$i * 5}] {
      min-height: #{($i * 5) + '%'};
      max-width: 100%;
    }
  }
}

*[layout-align^="start"] {
  justify-content: flex-start !important;
}

*[layout-align^="center"] {
  justify-content: center !important;
}

*[layout-align^="end"] {
  justify-content: flex-end !important;
}

*[layout-align^="space-around"] {
  justify-content: space-around !important;
}

*[layout-align^="space-between"] {
  justify-content: space-between !important;
}

*[layout-align$="start"] {
  align-items: flex-start !important;
  align-content: flex-start !important;
}

*[layout-align$="center"] {
  align-items: center !important;
  align-content: center !important;
  max-width: 100% !important;
}

*[layout-align$="end"] {
  align-items: flex-end !important;
  align-content: flex-end !important;
}

*[layout-align$="stretch"] {
  align-items: stretch !important;
  align-content: stretch !important;
}

.opacity-zero {
  opacity: 0;
}

.opacity-one {
  opacity: 1;
}

.highlight {
  display: inline-block;
  padding: 0 1.2rem;
  border-radius: .4rem;
  border: .1rem solid;
}

.highlight-green {
  @extend .highlight;
  background: rgba(166, 226, 45, .2);
  border-color: rgb(166, 226, 45);
}

.highlight-orange {
  @extend .highlight;
  background: rgba(253, 151, 32, .2);
  border-color: rgb(253, 151, 32);
}

.highlight-red {
  @extend .highlight;
  background: rgba(255, 0, 0, .2);
  border-color: rgb(255, 0, 0);
}

.highlight-blue {
  @extend .highlight;
  background: rgba(60, 147, 205, .2);
  border-color: rgb(60, 147, 205);
}

.fab-ios,
.fab-wp {
  box-shadow: 0 .4rem .6rem 0 rgba(0, 0, 0, .14), 0 .4rem .5rem rgba(0, 0, 0, .1);
}

.apple-weather-logo {
  @media (prefers-color-scheme: dark) {
    filter: invert(1);
  }
}

.pointer-events-none {
  pointer-events: none;
}

ion-card {
  > * {
    color: var(--ion-text-color);
  }

  ion-card-header {
    overflow: unset;
    text-overflow: unset;
    white-space: unset;
    padding: .6rem .6rem 1rem;
    margin: 1.2rem;
    border-bottom: solid .1rem #ccc;
    font-size: 2.2rem;
    font-weight: 500;
  }

  .card-content-ios,
  .card-content-md {
    padding: 0 2rem 2rem;
  }
}

.danger {
  color: $color-danger !important;
}

.alert-ios {
  .alert-upgrade-plan-button {
    font-weight: $font-weight-bold !important;
  }

  .alert-close-button {
    font-weight: $font-weight-normal !important;
  }
}

// Hide arrow buttons on number inputs in webkit browsers.
input[type=number] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.disabled {
  pointer-events: none;
  opacity: .5;
}

.back-button-override {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1000;
  cursor: pointer;
}

progress-bar {
  width: 100%;
}

.section-header {
  display: flex;
  align-items: center;
  height: 4rem;
  padding-left: 1.6rem;
  font-size: 1.6rem;
  font-weight: $font-weight-bold;
  margin: .8rem 0;
  //text-transform: uppercase;
  color: var(--ion-text-color);
  letter-spacing: .1rem;

  ion-icon {
    margin: .8rem 1.6rem .8rem 0;
    font-size: 2.8rem;
  }
}

.input-info {
  padding: 0.6rem 1.6rem 0;
  font-size: 1.4rem;
  font-style: italic;
  line-height: 1;
}

.stripe-card-form {
  padding: 2rem 2rem .5rem;
  border-radius: 6px;
  background: var(--ion-card-background);

  .credit-card-info {
    border-bottom: solid .1rem map-get($colors, input-border);

    .stripe-card-label {
      margin-bottom: .5rem;
      color: map-get($colors, primary);
    }

    .StripeElement {
      @media (prefers-color-scheme: dark) {
        filter: invert(1);
      }
    }
  }

  .proration-text {
    margin-top: .8rem;
    font-size: 1.3rem;
    font-style: italic;
    font-weight: 500;
    text-align: center;
  }

  .stripe-attribution {
    margin-top: 1.5rem;
    padding-bottom: 2rem;

    @media (prefers-color-scheme: dark) {
      filter: invert(1);
    }

    .stripe-logo {
      width: 12rem;
    }
  }
}
